import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';  
import { jwtDecode } from 'jwt-decode';  
  
const AuthContext = createContext(null);  
  
export const AuthProvider = ({ children }) => {  
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const [authToken, setAuthToken] = useState(null);  
  const [user, setUser] = useState(null);  
  
  const parseToken = (token) => {  
    const decoded = jwtDecode(token);  
    return {  
      userId: decoded.userId,  
      email: decoded.email,  
      firstName: decoded.firstName,  
      lastName: decoded.lastName,  
      systemRole: decoded.systemRole,  
      tenants: decoded.tenants,  
      expiresIn: decoded.exp  
    };  
  };  
  
  const checkTokenValidity = useCallback(() => {  
    const token = localStorage.getItem('jwt');  
    if (token) {  
      try {  
        const decodedToken = parseToken(token);  
        const currentTime = Date.now() / 1000;  
  
        if (decodedToken.expiresIn < currentTime) {  
          logout();  
        } else {  
          setIsAuthenticated(true);  
          setAuthToken(token);  
          setUser(decodedToken);  
        }  
      } catch (error) {  
        console.error('Invalid token:', error);  
        logout();  
      }  
    } else {  
      setIsAuthenticated(false);  
    }  
  }, []);  
  
  useEffect(() => {  
    checkTokenValidity();  
  }, [checkTokenValidity]);  
  
  const login = useCallback((token) => {  
    localStorage.setItem('jwt', token);  
    setAuthToken(token);  
    setIsAuthenticated(true);  
    const userData = parseToken(token);  
    setUser(userData);  
  }, []);  
  
  const logout = useCallback(() => {  
    localStorage.removeItem('jwt');  
    setIsAuthenticated(false);  
    setAuthToken(null);  
    setUser(null);  
  }, []);  
  
  const hasAdminTenant = useCallback(() => {  
    return user?.tenants?.some(  
      tenant =>   
        tenant.tenant_id === 'f06707b0-eeab-4c34-93ba-a3b01949ef77' &&   
        tenant.tenant_role === 'admin'  
    ) ?? false;  
  }, [user]); 

  const getTenantRole = useCallback((tenantId) => {  
    return user?.tenants?.find(tenant => tenant.tenant_id === tenantId)?.tenant_role ?? null;  
  }, [user]);  
  
  const getUserTenants = useCallback(() => {  
    return user?.tenants ?? [];  
  }, [user]);  
  
  const hasTenantPermission = useCallback((tenantId, role) => {  
    return user?.tenants?.some(  
      tenant =>   
        tenant.tenant_id === tenantId &&   
        tenant.tenant_role === role  
    ) ?? false;  
  }, [user]);   
  
  const value = {  
    isAuthenticated,  
    user,  
    setUser,  
    logout,  
    login,  
    authToken,  
    hasAdminTenant,
    getTenantRole,  
    getUserTenants,  
    hasTenantPermission  
  };  
  
  return (  
    <AuthContext.Provider value={value}>  
      {children}  
    </AuthContext.Provider>  
  );  
};  
  
export const useAuth = () => useContext(AuthContext);