import { useState, Fragment } from 'react';  
import { Dialog, Transition } from '@headlessui/react';  
import ceoai from '../ceoai.png'; 
import { useAuth } from '../contexts/AuthContext';  
  
export default function Login({ handleLogin }) {  
  const [email, setEmail] = useState('');  
  const [isDialogOpen, setIsDialogOpen] = useState(false); 
  const { login } = useAuth() 
    
  const sendLoginRequest = async (data) => {  
    try {  
      const response = await fetch('https://login.api.ceo.ai', {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/json',  
        },  
        body: JSON.stringify(data),  
      });  
      return await response.json();  
    } catch (error) {  
      console.error('Login request error:', error);  
      throw error;  
    }  
  };  
  
  const verifyOtp = async (email, otp) => {  
    try {  
      const response = await fetch('https://login.api.ceo.ai', {  
        method: 'POST',  
        headers: { 'Content-Type': 'application/json' },  
        body: JSON.stringify({ email, otp, action: 'verify' })  
      });  
      const data = await response.json(); 

      console.log('data: ' + JSON.stringify(data)) 
        
      if (data.error) {  
        throw new Error(data.error);  
      }  
  
      const token = data.data.token
      login(token) 

    } catch (error) {  
      console.error('OTP verification error:', error);  
    }  
  };  
  
  const handleEmailLogin = async () => {  
    try {  
      const response = await sendLoginRequest({   
        email,   
        action: "generate"   
      }); 

      console.log('response: ' + JSON.stringify(response)) 
              
      setIsDialogOpen(true);   
    } catch (error) {  
      console.error('Email login error:', error);  
    }  
  };  
  
  // Check URL for OTP parameters on component mount  
  useState(() => {  
    const urlParams = new URLSearchParams(window.location.search);  
    const email = urlParams.get('email');  
    const otp = urlParams.get('otp');  
      
    if (email && otp) {  
      verifyOtp(email, otp);  
    }  
  }, []);  
  
  return (  
    <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">  
      <div className="w-full max-w-sm space-y-10">  
        <div>  
          <img  
            className="mx-auto h-10 w-auto"  
            src={ceoai}  
            alt="CEO.ai"  
          />  
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">  
            Sign in to your account  
          </h2>  
          <p className="mt-2 text-center text-sm text-gray-600">  
            Enter your email to receive a login link  
          </p>  
        </div>  
  
        <div className="space-y-6">  
          <div>  
            <label htmlFor="email" className="sr-only">  
              Email address  
            </label>  
            <input  
              id="email"  
              name="email"  
              type="email"  
              required  
              value={email}  
              onChange={(e) => setEmail(e.target.value)}  
              className="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"  
              placeholder="Email address"  
            />  
          </div>  
  
          <div>  
            <button  
              onClick={handleEmailLogin}  
              className="flex w-full justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"  
            >  
              Sign in  
            </button>  
          </div>  
        </div>  
      </div>  
  
      <Transition appear show={isDialogOpen} as={Fragment}>  
        <Dialog   
          as="div"   
          className="relative z-10"   
          onClose={() => setIsDialogOpen(false)}  
        >  
          <Transition.Child  
            as={Fragment}  
            enter="ease-out duration-300"  
            enterFrom="opacity-0"  
            enterTo="opacity-100"  
            leave="ease-in duration-200"  
            leaveFrom="opacity-100"  
            leaveTo="opacity-0"  
          >  
            <div className="fixed inset-0 bg-black bg-opacity-25" />  
          </Transition.Child>  
  
          <div className="fixed inset-0 overflow-y-auto">  
            <div className="flex min-h-full items-center justify-center p-4 text-center">  
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">  
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">  
                  Check your email  
                </Dialog.Title>  
                <div className="mt-2">  
                  <p className="text-sm text-gray-500">  
                    We've sent a login link to your email address. Please check your inbox and click the link to sign in.  
                  </p>  
                </div>  
                <div className="mt-4">  
                  <button  
                    type="button"  
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-100 px-4 py-2 text-sm font-medium text-cyan-900 hover:bg-cyan-200"  
                    onClick={() => setIsDialogOpen(false)}  
                  >  
                    Got it  
                  </button>  
                </div>  
              </Dialog.Panel>  
            </div>  
          </div>  
        </Dialog>  
      </Transition>  
    </div>  
  );  
}  