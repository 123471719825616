import React from 'react';  
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';  
import Login from './components/Login';  
import { AuthProvider, useAuth } from './contexts/AuthContext';  
  
const MainApp = React.lazy(() => import('./MainApp'));  
  
const AppRoutes = () => {  
  const { isAuthenticated } = useAuth();  
  
  return (  
    <Routes>  
      <Route  
        path="/login"  
        element={!isAuthenticated ? <Login /> : <Navigate to="/" replace />}  
      />  
      <Route  
        path="/*"  
        element={  
          isAuthenticated ? (  
            <React.Suspense  
              fallback={  
                <div className="flex items-center justify-center min-h-screen">  
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-600" />  
                </div>  
              }  
            >  
              <MainApp />  
            </React.Suspense>  
          ) : (  
            <Navigate to="/login" replace />  
          )  
        }  
      />  
    </Routes>  
  );  
};  
  
const App = () => {  
  return (  
    <AuthProvider>  
      <Router>  
        <AppRoutes />  
      </Router>  
    </AuthProvider>  
  );  
};  
  
export default App;  